<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12">
                <h4>Add question bank
                </h4>
                <router-link :to="{ name: 'dashboard' }">
                  Dashboard
                </router-link>
                \
                Question bank
              </div>
              <!--                        <div class="col-4">-->
              <!--                            <h4>Manage Institution</h4>-->
              <!--                        </div>-->

            </div>
            <v-row>

              <v-col cols="12">
                <v-select label="Select category" :items="categories" v-model="question.question_category_id" outlined dense
                          item-text="title"
                          item-value="id"></v-select>
                <span class="text-danger" v-if="$v.question.question_category_id.$error">This information is required</span>
              </v-col>


              <v-col cols="12">
                <v-select
                    label="Program"
                    v-model="question.program_ids"
                    :items="programs"
                    item-text="title"
                    item-value="id"
                    outlined
                    dense
                    multiple
                    chip
                >
                </v-select>
                <span class="text-danger" v-if="$v.question.program_ids.$error">This information is required</span>
              </v-col>

              <v-col cols="12">
                <v-text-field
                    label="Question title"
                    placeholder="Question title"
                    v-model="question.question"
                    outlined
                    dense
                >
                </v-text-field>
                <span class="text-danger" v-if="$v.question.question.$error">This information is required</span>
              </v-col>

              <v-col cols="12">
                <v-select
                    label="Type"
                    v-model="question.type"
                    :items="question_bank_types"
                    item-text="title"
                    item-value="value"
                    outlined
                    dense
                >
                </v-select>
                <span class="text-danger" v-if="$v.question.type.$error">This information is required</span>
              </v-col>
              <v-col cols="12" v-if="question.type == 'file'">
                <v-file-input outlined dense v-model="question.image" label="Select File" prepend-inner-icon="mdi-link" prepend-icon=""></v-file-input>
                <!--                <span class="text-danger" v-if="$v.question.image.$error">File is required</span>-->
              </v-col>

              <v-col cols="12" v-for="(option,index) in options" :key="index">
                <v-row>
                  <v-col cols="8">
                    <v-text-field
                        :label="`Option ${index+1}`"
                        v-model="option.title"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <span class="text-danger" v-if="$v.options.$each[index].title.$error">Option {{ index + 1 }} is required</span>
                  </v-col>
                  <v-col cols="2">
                    <v-switch :disabled="!option.title" v-model="option.is_correct" @change="addToCorrectAnswer(index)"
                              label="Correct Answer">
                    </v-switch>
                  </v-col>
                  <v-col cols="2">
                    <span outlined v-if="index == (options.length -1)" @click="addOption">
                      <i class="fas fa-plus"></i> Add
                    </span>
                    <span class="ml-2" outlined v-if=" options.length>1" @click="removeOption(index)">
                       <i class="fas fa-trash"></i> Remove
                    </span>
                  </v-col>
                </v-row>


              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="float-right">

                  <v-btn
                      class="btn  btn-standard text-gray"
                      depressed
                      @click="redirectTo"
                  >Cancel
                  </v-btn>

                  <v-btn v-if="checkIsAccessible('question-bank', 'create')"
                      class="text-white btn btn-primary btn   font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
                      depressed
                      @click="createOrUpdate"
                      :loading="isBusy"
                  >Save
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import {QuizMixin} from "@/mixins/QuizMixin";
import {required} from "vuelidate/lib/validators";

export default {
  mixins: [QuizMixin],
  validations: {
    question: {
      "question": {required},
      "program_ids": {required},
      "answer": {required},
      "type": {required},
      "question_category_id": {required},
    },
    options: {
      $each:
          {title: {required}}
    }
  },
  data() {
    return {
      isBusy: false,
      edit: false,

    };
  },
  computed: {
    question_id() {
      return this.$route.params.id
    }
  },
  mounted() {
    if (this.question_id) {
      this.getQuestion(this.question_id)
    }
    this.questionBankCategories();
  },
  methods: {
    buildForEdit() {
      this.options = [];
      let opt = this.question.options
      let ans = this.question.answer.split("|");
      opt.forEach(ele => {
        let correct = 0
        ans.forEach(e => {
          if (ele == e) {
            correct = 1
          }
        })
        this.options.push({title: ele, is_correct: correct})
      })
      let answers = this.question.answer;
      answers = answers.split("|")
      answers.forEach(ele => {
        this.answerList.push(ele)
      })
      this.question.program_ids = this.question.program_ids
          .map(val => parseInt(val))
    },
    generateFd() {
      let fd = new FormData()
      for (let key in this.question) {
        if (key == 'image' && this.question['image']) {
          fd.append('image', this.question['image'])
        } else if (key == 'program_ids' && this.question['program_ids']) {
          fd.append('program_ids', this.question['program_ids'].join(","))
        } else {
          fd.append(key, this.question[key])
        }
      }
      return fd;
    },
    createOrUpdate() {
      this.isBusy = true
      this.question.answer = this.answerList.join("|")
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
          this.isBusy = false
        }, 4000)
        if (this.$v.question.answer.$error) {
          this.$snotify.error('Answer is not selected')
        }
      } else {
        this.question.options = this.buildOptions();
        let fd = this.generateFd();
        if (this.question.id) {
          this.updateQuestion(this.question.id, fd)
        } else {
          this.createQuestion(fd)
        }
      }
    },
    buildOptions() {
      let opt = [];
      this.options.forEach(ele => {
        opt.push(ele.title)
      })
      return opt.join('|');
    },
    redirectTo() {
      this.$router.push({name: 'question-bank'})
    }
  }
}
</script>
